import React from "react";
import {BasiqConnect} from "@basiq/connect-auth";
import BasiqLogo from "./assets/images/basiq.png";
import "./App.css";

class App extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      partnerLogo: null,
      partnerFetched: false
    };
  }


  render(){
    return(
      <div className="vc-inner-container">
        <div className="vc-logo-row">
          {this.state.partnerLogo ? <img src={this.state.partnerLogo} alt="Partner logo" />: null}
        </div>
        <BasiqConnect/>
        {this.state.partnerFetched ?
          <div className="vc-basiq-logo-row">
            <div className="vc-powered-by">Powered by</div>
            <a href="https://basiq.io/" target="_blank" rel="noopener noreferrer">
              <img src={BasiqLogo} alt="Basiq logo" />
            </a>
          </div> : null}
      </div>);
  }
}

export default App;
